import { gql } from '@apollo/client';

export const GET_USER = gql`
  query getUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      email
      role
      department
      company
      disabled
      lastIp
      lastLoginDate
      avatarUrl
      egnyteAuthToken
      projects {
        id
        name
        dbName
        objectModelVersion
      }
      lastWorkedOnProjectId
    }
  }
`;

export const GET_USER_EGNYTE_TOKEN = gql`
  query getUser($id: ID!) {
    getUser(id: $id) {
      egnyteAuthToken
    }
  }
`;

export const GET_USER_PROJECTS_BY_ID = gql`
  query getUser($id: ID!) {
    getUser(id: $id) {
      projects {
        id
        name
        dbName
        abbreviation
        status
        objectModelVersion
      }
      reportsConfig {
        type
        projectName
        projectId
        emailDelivery
      }
    }
  }
`;

export const GET_USER_PROJECTS_DETAILED_BY_ID = gql`
  query getUser($id: ID!) {
    getUser(id: $id) {
      projects {
        id
        name
        dbName
        objectModelVersion
        abbreviation
        status
        windowSystems
        doorSystems
        location
        scope
        size
        uValue
        acoustic
        structuralLoad
        movement
        glassMakeup
        hasPmu
        hasVmu
        archDrawingsDate
        structDrawingsDate
        specificationsDate
        mechDrawingsDate
        electDrawingsDate
        energyDrawingsDate
        startDate
        endDate
        users {
          id
          firstName
          lastName
        }
        projectManagers {
          id
          firstName
          lastName
        }
        client {
          id
          name
          address
          websiteUrl
          mainContactName
          mainContactPhone
          mainContactEmail
          type
          notes
        }
        architects {
          id
          name
          address
          websiteUrl
          mainContactName
          mainContactPhone
          mainContactEmail
          type
          notes
        }
        generalContractors {
          id
          name
          address
          websiteUrl
          mainContactName
          mainContactPhone
          mainContactEmail
          type
          notes
        }
        contractManagers {
          id
          name
          address
          websiteUrl
          mainContactName
          mainContactPhone
          mainContactEmail
          type
          notes
        }
        envelopeConsultants {
          id
          name
          address
          websiteUrl
          mainContactName
          mainContactPhone
          mainContactEmail
          type
          notes
        }
        shippingLines {
          id
          name
          address
          websiteUrl
          mainContactName
          mainContactPhone
          mainContactEmail
          type
          notes
        }
        engineersOnRecord {
          id
          name
          address
          websiteUrl
          mainContactName
          mainContactPhone
          mainContactEmail
          type
          notes
        }
        installSubContractors {
          id
          name
          address
          websiteUrl
          mainContactName
          mainContactPhone
          mainContactEmail
          type
          notes
        }
        shopDrawingSubContractors {
          id
          name
          address
          websiteUrl
          mainContactName
          mainContactPhone
          mainContactEmail
          type
          notes
        }
        windowSuppliers {
          id
          name
          address
          websiteUrl
          mainContactName
          mainContactPhone
          mainContactEmail
          type
          notes
        }
        doorSuppliers {
          id
          name
          address
          websiteUrl
          mainContactName
          mainContactPhone
          mainContactEmail
          type
          notes
        }
        extrusionSuppliers {
          id
          name
          address
          websiteUrl
          mainContactName
          mainContactPhone
          mainContactEmail
          type
          notes
        }
        hardwareSuppliers {
          id
          name
          address
          websiteUrl
          mainContactName
          mainContactPhone
          mainContactEmail
          type
          notes
        }
        glassSuppliers {
          id
          name
          address
          websiteUrl
          mainContactName
          mainContactPhone
          mainContactEmail
          type
          notes
        }
        gasketSuppliers {
          id
          name
          address
          websiteUrl
          mainContactName
          mainContactPhone
          mainContactEmail
          type
          notes
        }
        siliconeSuppliers {
          id
          name
          address
          websiteUrl
          mainContactName
          mainContactPhone
          mainContactEmail
          type
          notes
        }
        catalystSuppliers {
          id
          name
          address
          websiteUrl
          mainContactName
          mainContactPhone
          mainContactEmail
          type
          notes
        }
      }
      reportsConfig {
        type
        projectName
        projectId
        emailDelivery
      }
    }
  }
`;

export const GET_USERS = gql`
  query getUsers {
    getUsers {
      id
      firstName
      lastName
      email
      role
      department
      company
      disabled
      lastIp
      lastLoginDate
      avatarUrl
      projects {
        id
        name
        dbName
        objectModelVersion
      }
    }
  }
`;

// export const ADD_USER = gql`
//           mutation addUser($firstName: String!, $lastName: String!, $email: String!, $password: String!, $role: String!,
//             $department: [String]!, $company: String!){
//           addUser(firstName: $firstName, lastName: $lastName, email: $email, password: $password, role: $role,
//             department: $department, company: $company){
//               id
//               firstName
//               lastName
//               email
//               password
//               role
//               company
//               department
//           }
//         }
//       `;

export const ADD_USER = gql`
  mutation addUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $role: String!
    $department: [String]!
    $company: String!
    $projects: [ID]
  ) {
    addUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      role: $role
      department: $department
      company: $company
      projects: $projects
    ) {
      id
      firstName
      lastName
      email
      role
      company
      department
      projects {
        id
        name
        dbName
        objectModelVersion
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $firstName: String
    $lastName: String
    $email: String
    $role: String
    $department: [String]
    $company: String
    $disabled: Boolean
    $projects: [ID]
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      role: $role
      department: $department
      company: $company
      disabled: $disabled
      projects: $projects
    ) {
      id
      firstName
      lastName
      email
      role
      department
      company
      disabled
      lastIp
      lastLoginDate
      avatarUrl
      projects {
        id
        name
        dbName
        objectModelVersion
      }
      lastWorkedOnProjectId
    }
  }
`;

export const UPDATE_USER_LAST_PROJECT = gql`
  mutation updateUserLastProject($id: ID!, $lastWorkedOnProjectId: ID!) {
    updateUserLastProject(id: $id, lastWorkedOnProjectId: $lastWorkedOnProjectId) {
      id
      firstName
      lastName
      email
      role
      department
      company
      disabled
      lastIp
      lastLoginDate
      avatarUrl
      projects {
        id
        name
        dbName
        objectModelVersion
      }
      lastWorkedOnProjectId
    }
  }
`;

export const UPDATE_USER_AVATAR = gql`
  mutation updateUserAvatar($id: ID!, $avatarUrl: String!) {
    updateUserAvatar(id: $id, avatarUrl: $avatarUrl) {
      id
    }
  }
`;

export const UPDATE_USER_EGNYTE_AUTH_TOKEN = gql`
  mutation updateUserEgnyteAuthToken($id: ID!, $egnyteAuthToken: String!) {
    updateUserEgnyteAuthToken(id: $id, egnyteAuthToken: $egnyteAuthToken) {
      id
    }
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation updateUserPassword($id: ID!, $currentPassword: String!, $newPassword: String!) {
    updateUserPassword(id: $id, currentPassword: $currentPassword, newPassword: $newPassword) {
      id
    }
  }
`;

export const UPDATE_USER_REPORTS_CONFIG = gql`
  mutation updateUserReportsConfig($id: ID!, $userReportsConfig: [UserReportsConfigInputType]!) {
    updateUserReportsConfig(id: $id, userReportsConfig: $userReportsConfig) {
      reportsConfig {
        type
        projectName
        emailDelivery
      }
    }
  }
`;
