export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  // NATURE: 'NATURE'
};

export const SOFTWARE_VERSION = {
  major: '2',
  minor: '1',
  build: '1',
};
