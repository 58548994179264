export const GET_USER_DATA = 'GET_USER_DATA';
export const ADD_USER_DATA = 'ADD_USER_DATA';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
export const EDIT_USER = 'EDIT_USER';
export const CLEAR_EDIT_USER = 'CLEAR_EDIT_USER';

export const GET_PROJECT_DATA = 'GET_PROJECT_DATA';
export const ADD_PROJECT_DATA = 'ADD_PROJECT_DATA';
export const CLEAR_PROJECT_DATA = 'CLEAR_PROJECT_DATA';
export const CLEAR_PROJECT_ID = 'CLEAR_PROJECT_ID';
export const EDIT_PROJECT = 'EDIT_PROJECT';

export const SET_DB_NAME = 'SET_DB_NAME';
export const SET_CURRENT_PROJECT_ID = 'SET_CURRENT_PROJECT_ID';
export const SET_CURRENT_PROJECT_ABRV = 'SET_CURRENT_PROJECT_ABRV';
export const SET_CURRENT_PROJECT_WINDOW_SYSTEM = 'SET_CURRENT_PROJECT_WINDOW_SYSTEM';
export const SET_CURRENT_PROJECT_OBJECT_MODEL_VERSION = 'SET_CURRENT_PROJECT_OBJECT_MODEL_VERSION';

export const EDIT_PROJECT_AFFILIATE = 'EDIT_PROJECT_AFFILIATE';

export const ADD_SUBMISSION_ENTRY_DATA = 'ADD_SUBMISSION_ENTRY_DATA';
export const CLEAR_SUBMISSION_ENTRY_DATA = 'CLEAR_SUBMISSION_ENTRY_DATA';
export const ADD_CALENDAR_ENTRY = 'ADD_CALENDAR_ENTRY';
export const ADD_SUBMISSION_COMMENT = 'ADD_SUBMISSION_COMMENT';
export const CLEAR_SUBMISSION_COMMENT = 'CLEAR_SUBMISSION_COMMENT';

export const SET_SCANNED_PRODUCT_DATA = 'SET_SCANNED_PRODUCT_DATA';

export const SET_CALENDAR_FILTERS = 'SET_CALENDAR_FILTERS';
// export const SET_CALENDAR_SHIPMENTS_FILTER = 'SET_CALENDAR_SHIPMENTS_FILTER';
export const SET_CALENDAR_DATE_TYPE_FILTER = 'SET_CALENDAR_DATE_TYPE_FILTER';
